var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transaction"},[_c('header-page',[_c('router-link',{attrs:{"to":{ name: 'stock.index' }}},[_c('span',{staticClass:"size14 fw-bold-700",staticStyle:{"color":"#0b5fff","margin-right":"5px"}},[_vm._v("Pengaturan Stok")])]),_c('span',{staticClass:"text-dark text-darken-5",staticStyle:{"margin-right":"5px"}},[_vm._v(">")]),_c('span',{staticClass:"text-dark fw-bold-700 size14"},[_vm._v("Stok Lokasi SKU")])],1),_c('div',{staticClass:"transaction__filter--container py-2 pl-0 pr-2"},[_c('b-button-group',_vm._l((_vm.listFilter),function(item,index){return _c('b-button',{key:(item + "-" + index),staticClass:"filter--button",class:{ active: item == _vm.filter },attrs:{"id":("button--filter" + index)},on:{"click":function($event){_vm.$store.commit('stockWarehouse/setIsWarehouse', true),
            (_vm.filter = item),
            (_vm.comp =
              item == 'Produk Masuk'
                ? 'TableIncomeProduct'
                : item == 'Stok Aktual'
                ? 'TableStockActual'
                : 'TableEceran')}}},[_vm._v(" "+_vm._s(item)+" ")])}),1),_c('div',{staticClass:"d-flex search__export align-items-center"},[(_vm.filter === 'Produk Masuk' || _vm.filter === 'Stok Aktual')?_c('FilterButton',{attrs:{"is_actual":_vm.filter == 'Stok Aktual' ? true : false,"is_transaction":true,"is_stock":true},on:{"filterBase":_vm.getFilterBase}}):_vm._e(),_c('div',[(_vm.filter == 'Stok Aktual')?_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Nama Produk"},model:{value:(_vm.filterStockActual.search),callback:function ($$v) {_vm.$set(_vm.filterStockActual, "search", $$v)},expression:"filterStockActual.search"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1):_vm._e()],1),(
          _vm.checkPermission('add stok gudang') && _vm.comp == 'TableIncomeProduct'
        )?_c('b-button',{attrs:{"variant":"outline-secondary py-1 bg-secondary text-white"},on:{"click":_vm.goToAddIncomingProduct}},[_c('span',[_vm._v("Tambah Produk Masuk")])]):(
          _vm.checkPermission('add stok gudang') && _vm.comp == 'TableEceran'
        )?_c('b-button',{attrs:{"variant":"outline-secondary py-1 bg-secondary text-white"},on:{"click":function($event){_vm.$store.commit('stockWarehouse/setIsEdit', false),
            _vm.$bvModal.show('modal-add-stock-eceran')}}},[_c('span',[_vm._v("Tambah Stok Eceran")])]):_vm._e()],1)],1),(_vm.comp == 'TableIncomeProduct')?_c('div',{staticClass:"d-flex search-export justify-content-between p-1 bg-white"},[_c('div',{staticClass:"search__input w-100 mr-1"},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Cari Pemasok"},model:{value:(_vm.filters.supplier_name),callback:function ($$v) {_vm.$set(_vm.filters, "supplier_name", $$v)},expression:"filters.supplier_name"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('div',{staticClass:"search__input w-100"},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Cari No Surat Jalan"},model:{value:(_vm.filters.delivery_number),callback:function ($$v) {_vm.$set(_vm.filters, "delivery_number", $$v)},expression:"filters.delivery_number"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1)]):_vm._e(),_c('keep-alive',[(_vm.filter === 'Produk Masuk')?_c('TableIncomeProduct',{attrs:{"get-data-product":_vm.getStockWarehouse,"result-product":_vm.result,"is-loading":_vm.isLoading},on:{"filterIncomeProduct":_vm.getFilterIncomeProduct}}):_vm._e(),(_vm.filter === 'Produk Eceran')?_c('TableEceran',{attrs:{"result-retail-product":_vm.resultRetail,"get-retail-product":_vm.getRetailProducts,"messages":_vm.messages,"is-edit":_vm.isEdit,"is-loading":_vm.isLoading,"submit-retail":_vm.submit},on:{"filterRetail":_vm.getFilterRetail}}):_vm._e(),(_vm.filter === 'Stok Aktual')?_c('TableStockActual',{attrs:{"result-actual":_vm.resultActual,"get-stock-actual":_vm.getStockActual,"is-loading":_vm.isLoading,"is-warehouse":_vm.isWarehouse},on:{"filterStockActual":_vm.getFilterStockActual}}):_vm._e()],1),_c('ModalIncomeProduct',{attrs:{"get-stock-warehouse":_vm.getStockWarehouse}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }