<template>
  <b-container class="mt-3" :class="$router.name == 'payment.split' ? 'px-0' : ''">
    <b-row class="justify-content-center">
      <b-col :class="$router.name == 'payment.split' ? 'px-0' : ''" :cols="$route.name == 'stock.add.index'  || $route.name == 'debt.index' ? 12 : column">
        <b-form @submit.prevent="submitForm">
          <b-row>
            <!-- Nama Bank Penerima -->
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="">Rekening {{ this.$route.name === 'stock.add.index' ? 'Pengirim' : 'Penerima' }} <span class="text-danger">*</span></label>
                <b-form-select
                  v-model="formPayload.card_bank_account_payment_uuid"
                  :options="optionsBankAccount"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- Masukan Nominal -->
            <b-col
              v-if="$route.name == 'pos.index'"
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="">Masukan Nominal <span class="text-danger">*</span></label>
                <input
                  :id="`amount-card-${indexNominal}`"
                  class="focus-0 custom__input pl-1 w-100"
                  type="text"
                  :value="formPayload.cash_total_payment"
                  @input="getAmountCard"
                  @change="getAmountCard"
                  required
                >
              </b-form-group>
            </b-col>

          <!-- Nama Bank Pengirim -->
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="">Pilih Bank Pengirim</label>
                <b-form-select
                  v-model="formPayload.card_bank_name"
                  :options="options"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- Nomor Card -->
            <b-col
              cols="5"
              class="mb-2"
            >
              <b-form-group
                class="custom__form--input mb-0"
                label="Nomor Kartu"
                label-for="v-card"
              >
                <b-form-input
                  id="v-card"
                  v-model="formPayload.card_number"
                  class="custom__input"
                  type="number"
                  placeholder="x-xxxx-xxxx"
                />
              </b-form-group>
            </b-col>

            <!-- Exp Date -->
            <b-col
              cols="7"
              class="mb-2"
            >
              <b-form-group
                class="custom__form--input mb-0"
                label="Exp Date"
                label-for="exp-date"
              >
                <b-form-input
                  id="exp-date"
                  v-model="date"
                  class="custom__input pt-1"
                  type="month"
                  placeholder="mm/yy"
                />
              </b-form-group>
            </b-col>



            <!-- Button -->
            <b-col
              v-if="!isButtonHide"
              cols="12"
              class="mb-2 mt-2"
            >
              <b-button
                type="submit"
                class="btn-block size16 py-1 border-8"
              >
                Selanjutnya
              </b-button>
            </b-col>

          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormSelect,
} from 'bootstrap-vue'
import { formatRupiah } from '@/auth/utils'
import moment from 'moment'

export default {
  props: {
    isSubmit: {
      type: Boolean
    },
    submit: {
      type: Function,
    },
    createUtang: {
      type: Function,
    },
    isButtonHide: {
      type: Boolean,
      default: false,
    },
    column: {
      type: Number,
      default: 5,
    },
    formData: {
      type: Object,
    },
    indexNominal: {
      type: Number,
    }
  },
  setup() {
    return {
      formatRupiah
    }
  },
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormSelect,
  },
  data() {
    return {
      options: [],
      optionsBankAccount: [],
      moment,
      formPayload: {
        card_bank_name: '',
        bank_account_name: '',
        card_bank_account_payment_uuid: '',
        card_name: '',
        card_number: '',
        card_exp_date: null,
        cash_total_payment: ''
      },
      date: '',
    }
  },
  watch: {
    date(value) {
      this.formPayload.card_exp_date = moment(value).format('MM/YY') == 'Invalid date' ? value : moment(value).format('MM/YY')
    },
    formData: {
      handler(value) {
        this.formPayload = value
        
        if (value.card_exp_date) {
          this.date = this.convertDate(value.card_exp_date)
        }
        setTimeout(() => {
          this.getAmountCard()
        }, 300);
      },
      deep: true,
    },
    formPayload: {
      handler(value) {
        const payload = {
          ...value,
          // payment_method: 'card'
        }
        this.$emit('payloadCard', payload)
        this.$emit('formPayment', this.formPayload)
      },
      deep: true,
    },
  },
  destroyed() {
    this.formPayload = {
      card_bank_name: '',
      bank_account_name: '',
      card_bank_account_payment_uuid: '',
      card_name: '',
      card_number: '',
      card_exp_date: '',
    }
  },
  created() {
    this.getBank()
    this.getBankAccounts()
  },
  mounted() {
    const transaction_query = JSON.parse(
      localStorage.getItem("transaction_query")
    );

    if (transaction_query.data) {
      const decodeData = transaction_query.data
      setTimeout(() => {
        for (let index = 0; index < decodeData.length; index++) {
          const element = decodeData[index];
          if (this.indexNominal === index && element.payment_method == 'card') {
            this.formPayload = {
              ...element
            }
            this.date = element.card_exp_date
          }
        }
      }, 500);
    }

    // Re assign cash total payment on same index
    this.formPayload.cash_total_payment = this.formData.cash_total_payment

    // if (transaction_query.total) {
    //   setTimeout(() => {
    //     this.formPayload.cash_total_payment = transaction_query.total || '';
    //     setTimeout(() => {
    //       this.getAmountCard();
    //     }, 200);
    //   }, 300);
    // }
  },
  methods: {
    getAmountCard() {
      const rupiah = document.getElementById(`amount-card-${this.indexNominal}`)
      rupiah.value = this.formatRupiah(rupiah.value, '')
      this.formPayload.cash_total_payment = rupiah.value
    },
    submitForm() {
      if (this.$route.name == 'stock.add.index') {
        // $route.name == 'debt.index' ? createUtang() : (isSubmit ? submit() : $store.commit('formWizard/setStep', 2)
        this.$emit('formPayment', this.formPayload)
        this.$bvModal.hide('modal-form-payment')
        return
      }
      if (this.$route.name == 'debt.index') {
        this.createUtang()
        return
      }
      if (this.isSubmit) {
        this.submit()
        return
      } else {
        this.$store.commit('formWizard/setStep', 2)
        return
      }
    },
    async getBank() {
      await this.$store.dispatch('bank/getData', {
        uuid: '',
        params: {
          per_page: 30,
        },
      }).then(result => {
        const items = result.data.data.data
        // eslint-disable-next-line array-callback-return
        items.map(el => {
          this.options.push({
            value: el.name,
            text: el.name,
          })
        })
        this.options.unshift({ value: '', text: 'Pilih Bank' })
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    async getBankAccounts() {
      await this.$store.dispatch('bankAccounts/getData', {
        params: {
          per_page: 100,
        },
      }).then(result => {
        const items = result.data.data.data;
        const filtered = items.filter(el => el.account_type === 'Tabungan');
        // eslint-disable-next-line array-callback-return
        items.map(el => {
          this.optionsBankAccount.push({
            value: el.uuid,
            text: `${el.owner_name} - ${el.bank?.name} - ${el.account_number}`,
          });
        });
        this.optionsBankAccount.unshift({ value: '', text: 'Pilih Rekening' });
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    },
    convertDate(dateStr) {
      // Split the date string into month and year components
      var parts = dateStr.split('/');
      
      // Extract the month and year
      var month = parts[0];
      var year = parts[1];
      
      // Convert the two-digit year to a four-digit year
      if (year && year.length === 2) {
        // Assume that years between 00-29 are in the 2000s (e.g., 2021)
        // and years between 30-99 are in the 1900s (e.g., 1930)
        var prefix = (parseInt(year) >= 30) ? '19' : '20';
        year = prefix + year;
      }
      
      // Concatenate the month and year with a hyphen separator
      var convertedDate = year + '-' + month;
      
      return convertedDate;
    }

  },
}
</script>
