<template>
  <div class="section">
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1 mt-5"
    >
      <b-spinner label="Loading..." />
    </div>
    <vue-good-table
      v-else
      :columns="columns"
      :rows="stocks"
      :fixed-header="false"
      :sort-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'action'" />
        <span
          v-else-if="props.column.field === 'name_product'"
          class="text-dot"
        >
          Produk Eceran
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Produk Eceran -->
        <span
          v-if="props.column.field === 'name_product'"
        >
          <span class="text-dot">{{ props.row.name_product }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              id="dropdown-dropleft"
              class="d-flex"
              right
            >
              <template
                #button-content
                class="btn-white text-center"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item @click="goToDetail(props.row)">
                <span
                  class="pr-5"
                >
                  Lihat Detail
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      v-if="rows && !isLoading"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10','15','20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan {{ (rows.total > 0) ? (filter.per_page * (rows.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.total > 0) ? Math.min(filter.per_page * rows.current_page, rows.total) : 0 }} dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getRetailProduct"
            />
          </b-col>
        </b-row>
      </div>
    </div>

    <ModalAddStockEceran
      :is-edit="isEdits"
      :detail-result="detailResult"
      :messages="messages"
      :submit-retail="submitRetail"
    />
  </div>
</template>

<script>
import {
  BDropdown, BFormSelect, VBToggle, BRow, BCol, BSpinner, BDropdownItem,
} from 'bootstrap-vue'
import ModalAddStockEceran from '@/components/Stock/Gudang/ModalAddStockEceran.vue'

import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BSpinner,
    BFormSelect,
    ModalAddStockEceran,
    BRow,
    BCol,
    BDropdownItem,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    messages: {
      type: '',
    },
    submitRetail: {
      type: Function,
    },
    resultRetailProduct: {
      type: Object,
    },
    getRetailProduct: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
    },
    isEdit: {
      type: Boolean,
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'Tanggal',
          field: 'date',
        },
        {
          label: 'Produk Eceran',
          field: 'product.alias_name',
        },
        {
          label: 'Produk Induk',
          field: 'product.parent_product.name',
        },
        {
          label: 'Satuan',
          field: 'product.parent_product.uom.name',
        },
        {
          label: 'Satuan Eceran',
          field: 'product.uom.name',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      stocks: [],
      filter: {
        per_page: 10,
      },
      detailResult: {},
      isEdits: false,
    }
  },
  watch: {
    isEdit(value) {
      this.isEdits = value
    },
    resultRetailProduct: {
      handler(value) {
        this.rows = value
        this.stocks = value.data
      },
      deep: true,
    },
    filter: {
      handler(value) {
        this.$emit('filterRetail', value)
      },
      deep: true,
    },
  },
  mounted() {
    this.rows = this.resultRetailProduct
    this.stocks = this.resultRetailProduct.data
  },
  methods: {
    goToDetail(item) {
      this.$store.commit('stockWarehouse/setIsEdit', true)
      this.detailResult = item
      this.$bvModal.show('modal-add-stock-eceran')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
// .vgt-responsive {
//   height: calc(100vh - 330px) !important;
// }
// .vgt-responsive {
//   height: 68vh;
//   background-color: #fff;
// }
.detail__button {
  background-color: #FFFFFF !important;
  border: 1px solid #E4E5EC !important;
  color: $dark !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
</style>
