<template>
  <div class="transaction">
    <header-page>
      <router-link :to="{ name: 'stock.index' }">
        <span
          class="size14 fw-bold-700"
          style="color: #0b5fff; margin-right: 5px"
          >Pengaturan Stok</span
        >
      </router-link>
      <span class="text-dark text-darken-5" style="margin-right: 5px">></span>
      <span class="text-dark fw-bold-700 size14">Stok Lokasi SKU</span>
    </header-page>
    <div class="transaction__filter--container py-2 pl-0 pr-2">
      <b-button-group>
        <b-button
          v-for="(item, index) in listFilter"
          :id="`button--filter${index}`"
          :key="`${item}-${index}`"
          class="filter--button"
          :class="{ active: item == filter }"
          @click="
            $store.commit('stockWarehouse/setIsWarehouse', true),
              (filter = item),
              (comp =
                item == 'Produk Masuk'
                  ? 'TableIncomeProduct'
                  : item == 'Stok Aktual'
                  ? 'TableStockActual'
                  : 'TableEceran')
          "
        >
          {{ item }}
        </b-button>
      </b-button-group>
      <div class="d-flex search__export align-items-center">
        <FilterButton
          v-if="filter === 'Produk Masuk' || filter === 'Stok Aktual'"
          :is_actual="filter == 'Stok Aktual' ? true : false"
          :is_transaction="true"
          :is_stock="true"
          @filterBase="getFilterBase"
        />
        <div>
          <b-input-group v-if="filter == 'Stok Aktual'">
            <b-form-input
              v-model="filterStockActual.search"
              placeholder="Nama Produk"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
        <b-button
          v-if="
            checkPermission('add stok gudang') && comp == 'TableIncomeProduct'
          "
          variant="outline-secondary py-1 bg-secondary text-white"
          @click="goToAddIncomingProduct"
        >
          <span>Tambah Produk Masuk</span>
        </b-button>
        <b-button
          v-else-if="
            checkPermission('add stok gudang') && comp == 'TableEceran'
          "
          variant="outline-secondary py-1 bg-secondary text-white"
          @click="
            $store.commit('stockWarehouse/setIsEdit', false),
              $bvModal.show('modal-add-stock-eceran')
          "
        >
          <span>Tambah Stok Eceran</span>
        </b-button>
      </div>
    </div>
    <div
      class="d-flex search-export justify-content-between p-1 bg-white"
      v-if="comp == 'TableIncomeProduct'"
    >
      <div class="search__input w-100 mr-1">
        <b-input-group>
          <b-form-input
            v-model="filters.supplier_name"
            placeholder="Cari Pemasok"
          />
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="search__input w-100">
        <b-input-group>
          <b-form-input
            v-model="filters.delivery_number"
            placeholder="Cari No Surat Jalan"
          />
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>

    <keep-alive>
      <!-- 'Produk Masuk', 'Produk Eceran', 'Stok Aktual' -->
      <TableIncomeProduct
        v-if="filter === 'Produk Masuk'"
        :get-data-product="getStockWarehouse"
        :result-product="result"
        :is-loading="isLoading"
        @filterIncomeProduct="getFilterIncomeProduct"
      />
      <TableEceran
        v-if="filter === 'Produk Eceran'"
        :result-retail-product="resultRetail"
        :get-retail-product="getRetailProducts"
        :messages="messages"
        :is-edit="isEdit"
        :is-loading="isLoading"
        :submit-retail="submit"
        @filterRetail="getFilterRetail"
      />
      <TableStockActual
        v-if="filter === 'Stok Aktual'"
        :result-actual="resultActual"
        :get-stock-actual="getStockActual"
        :is-loading="isLoading"
        :is-warehouse="isWarehouse"
        @filterStockActual="getFilterStockActual"
      />
      <!-- <component
        :is="comp"
        :result-product="result"
        :get-data-product="getStockWarehouse"
        :result-retail-product="resultRetail"
        :get-retail-product="getRetailProducts"
        :result-actual="resultActual"
        :get-actual="getStockActual"
        @filterStockActual="getFilterStockActual"
      /> -->
    </keep-alive>
    <ModalIncomeProduct :get-stock-warehouse="getStockWarehouse" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import {
  BButton,
  BButtonGroup,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import moment from "moment";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import HeaderPage from "@/components/HeaderPage.vue";
import TableIncomeProduct from "@/components/Stock/Gudang/TableIncomeProduct.vue";
import TableStockActual from "@/components/Stock/Gudang/TableStockActual.vue";
import TableEceran from "@/components/Stock/Gudang/TableEceran.vue";
import ModalIncomeProduct from "@/components/Stock/Modal/ModalIncomeProduct.vue";
import FilterButton from "@/components/BaseFilter.vue";

export default {
  title() {
    return "Stok Lokasi SKU";
  },
  components: {
    BButton,
    HeaderPage,
    BButtonGroup,
    FilterButton,
    TableIncomeProduct,
    TableStockActual,
    TableEceran,
    ModalIncomeProduct,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      totalBuy: this.$store.state.cashier.totalBuy,
      listFilter: ["Produk Masuk", "Produk Eceran", "Stok Aktual"],
      filter: "Produk Masuk",
      comp: "TableIncomeProduct",
      result: {},
      resultRetail: {},
      resultActual: {},
      filterStockActual: {
        search: "",
      },
      filters: {
        per_page: 10,
      },
      messages: "",
      isLoading: false,
      formPayload: this.$store.state.stockWarehouse.payloadRetail,
      isEdit: false,
      isWarehouse: true,
    };
  },
  watch: {
    filterStockActual: {
      handler(value) {
        this.filters.search = value.search;
        this.getStockActual();
      },
      deep: true,
    },
    filters: {
      handler: _.debounce(function (value) {
        if (this.filter === "Produk Masuk") {
          this.getStockWarehouse();
        }
        if (this.filter === "Produk Eceran") {
          this.getRetailProducts();
        }
        if (this.filter === "Stok Aktual") {
          this.getStockActual();
        }
      }, 300),
      deep: true,
    },
    "$store.state.stockWarehouse.payloadRetail": {
      handler(value) {
        this.formPayload = value;
      },
      deep: true,
    },
  },
  created() {
    const userPermission = localStorage.getItem("userPermission");

    if (userPermission && !userPermission.includes("list stok gudang")) {
      this.listFilter = ["Stok Aktual"];
      this.filter = "Stok Aktual";
      this.comp = "TableStockActual";
      this.getStockActual();
    } else if (
      userPermission &&
      !userPermission.includes("list aktual stok gudang")
    ) {
      this.listFilter = ["Produk Masuk", "Produk Eceran"];
      this.filter = "Produk Masuk";
      this.comp = "TableIncomeProduct";
      this.getStockWarehouse();
      this.getRetailProducts();
    } else {
      this.getStockWarehouse();
      this.getRetailProducts();
      this.getStockActual();
    }
  },
  methods: {
    goToAddIncomingProduct() {
      this.$store.commit("stockWarehouse/setFormSupplier", {});
      this.$store.commit("stockWarehouse/setIsWarehouse", true);
      this.$router.push({ name: "stock.add.index", query: { warehouse: "1" } });
    },
    getFilterIncomeProduct(value) {
      this.filters = value;
    },
    getFilterRetail(value) {
      this.filters = value;
    },
    getFilterBase(value) {
      this.filters = value;
    },
    submit() {
      this.$store.commit("stockWarehouse/setIsLoading", true);
      const form = this.preparePayload();
      this.$store
        .dispatch("stockWarehouse/postRetailProducts", {
          uuid: "",
          payload: form,
        })
        .then(() => {
          successNotification(
            this,
            "Success",
            "Stok gudang berhasil ditambahkan"
          );
          this.getRetailProducts();
          this.$bvModal.hide("modal-add-stock-eceran");
          this.$store.commit("stockWarehouse/setIsLoading", false);
          this.$store.commit("stockWarehouse/setPayloadRetail", {
            retail_product_uuid: "",
            date: moment(new Date()).format("YYYY-MM-DD"),
            warehouse_uuid: "",
            qty_parent: 0,
          });
          this.messages = "";
        })
        .catch((error) => {
          this.$store.commit("stockWarehouse/setIsLoading", false);
          this.messages = error.response.data.meta.messages;
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, "Error", error.response.data.meta.messages);
          }
        });
    },
    preparePayload() {
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          form.append(key, this.formPayload[key]);
        }
      }
      return form;
    },
    getFilterStockActual(value) {
      this.filters.per_page = value.per_page;
    },
    async getStockWarehouse(page = 1) {
      this.isLoading = true;
      const queryParams = this.filters;
      queryParams.page = page;
      await this.$store
        .dispatch("stockWarehouse/getData", {
          uuid: "",
          params: queryParams,
        })
        .then((result) => {
          this.isLoading = false;
          this.result = result.data.data;
        })
        .catch((err) => {
          if (err.response.data.meta.messages) {
            errorNotification(this, "Oops!", err.response.data.meta.messages);
          }
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getRetailProducts(page = 1) {
      this.isLoading = true;
      const queryParams = this.filters;
      queryParams.page = page;
      await this.$store
        .dispatch("stockWarehouse/getRetailProducts", {
          uuid: "",
          params: queryParams,
        })
        .then((result) => {
          this.isLoading = false;
          this.resultRetail = result.data.data;
        })
        .catch((err) => {
          if (err.response.data.meta.messages) {
            errorNotification(this, "Oops!", err.response.data.meta.messages);
          }
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getStockActual(page = 1) {
      this.isLoading = true;
      const queryParams = this.filters;
      queryParams.page = page;
      await this.$store
        .dispatch("stockWarehouse/getStockActual", {
          uuid: "",
          params: queryParams,
        })
        .then((result) => {
          this.isLoading = false;
          this.resultActual = result.data.data;
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.transaction {
  position: relative;

  .vgt-table.bordered {
    thead {
      th:last-child {
        text-align: center;
      }
    }
    td {
      border: none;
      border-bottom: 1px solid #ebe9f1;
      vertical-align: middle;
    }
    th {
      border: none;
      border-bottom: 1px solid #ebe9f1;
      color: #6b6c7e;
      font-weight: 700;
    }
  }

  .page-item {
    .page-link {
      font-weight: 600;
      color: $secondary-2 !important;
      background-color: transparent;
    }
    &.active {
      .page-link {
        border-radius: 0;
        background-color: $light--1;
        font-weight: 600;
        color: $secondary-2 !important;
      }
    }
  }

  .page-item.prev-item ~ .page-item:nth-child(2) .page-link {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .page-item.prev-item ~ .page-item:nth-last-child(2) .page-link {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .page-item.next-item .page-link:active,
  .page-item.next-item .page-link:hover {
    background-color: transparent !important;
  }

  .pagination__container {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
    border: 1px solid #e4e5ec;

    .dropdown {
      button {
        font-size: 14px;
        color: $dark;
        font-weight: 600;

        &:hover {
          background-color: transparent;
        }
      }
    }

    small {
      color: $secondary-2;
      font-size: 14px;
      font-weight: 400;
    }
  }

  #dropdown-dropleft {
    .dropdown-toggle {
      border-color: transparent !important;
      background-color: transparent !important;
      color: $dark !important;

      &:hover {
        border-color: transparent !important;
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
  .transaction__filter--container {
    display: flex;
    justify-content: space-between;

    .search__export {
      .input-group {
        width: 23.451vw;
        height: 48px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

        input {
          background: #fff;
          height: 48px;
          border: none;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        .input-group-append {
          .input-group-text {
            background-color: #fff !important;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border: none;
          }
        }
      }
      #search {
        width: 278px;
        height: 48px;
        border: none;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
      }

      .btn.bg-white {
        border: none !important;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        border-radius: 8px;

        img {
          margin-right: 8px;
        }

        span {
          color: $dark;
          font-size: 14px;
          font-weight: 800;
        }
      }
    }

    .filter--button {
      border: none !important;
      color: $light--2 !important;
      background-color: transparent !important;
      border-color: transparent !important;

      &.active {
        color: $secondary !important;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: -48%;
          left: 0;
          width: 50%;
          margin-left: 25%;
          border-radius: 4px 4px 0px 0px;
          border: 2px solid $secondary;
        }
      }

      &:hover {
        box-shadow: none !important;
      }
    }
  }
}
</style>
